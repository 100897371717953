import { Link } from 'gatsby';
import React from 'react';
import './exito.scss'

const ExitoPage: React.FC = () => {
    return (
        <div className="exito-page">
            <Link className="close-icon" to="/">
                <img src="close-icon.svg" />
            </Link>
            <div className="container">
                <div className="flex"></div>

                <div className="message">
                    <div className="success-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 940 914"><path fill="#f69eaa" d="M730 97.1c6.7-6 14.6-11.9 24-12 12.3-.3 23.4 6 33.6 12.3a644 644 0 0 1 40.3 27.7c9.8 7 19.4 14.4 28 23 6 6.1 11.1 14 11 22.9.1 12-6.8 22.3-12.4 32.4-9.2 14.7-19.3 28.8-28.8 43.2-121.4 179.7-243 359.1-364.8 538.4-9 13.4-18.6 26.6-31.2 36.7a48.5 48.5 0 0 1-35.8 12c-15-1.4-27.8-10.2-39.5-19-6-4.4-11.1-9.7-16.4-14.8-76.5-73.4-150.4-149.5-225.7-224.3a62.2 62.2 0 0 1-18.5-27.1c-3-10.4-.1-21.6 4.8-31 4.2-8.3 10.7-15.2 16.5-22.4 13.1-15.5 26.1-31.2 41.8-44.2a51.9 51.9 0 0 1 26.1-11.8c11.5-1.3 22.3 4.2 31.7 10.2 15.5 10.9 29 24.2 42.6 37.5a1076 1076 0 0 1 64.4 70.6 7017 7017 0 0 0 49.6 58C479.3 457 587.6 299 696.1 141.1c10.7-15.2 21-30.7 34-44Z" /></svg>
                    </div>
                    <h2 className="title text-uppercase text-primary font-black">MENSAJE ENVIADO</h2>
                    <p className="text">
                        Muchas gracias por contactarte con nostros.<br />
                        En breve, un representante se comunicará<br />
                        en el horario de contacto indicado.
                    </p>
                </div>

                <Link className="logo" to="/">
                    <img src="codevelopers-logo-white.svg" />
                </Link>
            </div>
        </div>
    )
}

export default ExitoPage
